<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template #item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ item.day ? monthToString(item.month) : capitalize(monthToString(item.month)) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template #item-Enkele_ritten="{ item }">
        <div>
          <i class="fas fa-car text-gray-400"></i>
          {{ item.aantal_enkele_ritten }}
        </div>
      </template>
      <template #item-Gemiddelde_prijs="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.enkeleRitPrijs) }}
        </div>
      </template>
      <template #item-Gemiddelde_basisprijs="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.onderaannemerBasisPrijs) }}
        </div>
      </template>
      <template #item-Gemiddelde_commisie="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.onderaannemerCommissie) }}
        </div>
      </template>
      <template #item-Winstmarge="{ item }">
        <div>
          {{ formatPrice(item.winstmarge) }}&nbsp;%
        </div>
      </template>
    </UITableResponsive>

    <div v-if="data.statistieken.length > 1" :key="selectedCategory" class="mt-8">
      <UISelect v-model="selectedCategory" label="Grafiek" :options="categories" />
      <ChartsBar :label="selectedCategory" :categories="chartCategories" :values="chartValues" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import clone from 'just-clone'

import UISelect from '@/components/UI/Select/Select.vue'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { monthToString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import { capitalize, formatHeader } from '@/functions/formatText'
import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  is_bevestigd: {
    type: Boolean,
    default: true,
  },
  timefield: {
    type: String,
    default: null,
  },
  filters: {
    type: Object,
    required: true,
  },
})

const ChartsBar = defineAsyncComponent(() => import('@/components/Charts/Bar.vue'))

const headers = [
  'Datum',

  'Enkele_ritten', // aantal_enkele_ritten
  'Gemiddelde_prijs', // enkeleRitPrijs
  'Gemiddelde_basisprijs', // onderaannemerBasisPrijs
  'Gemiddelde_commisie', // onderaannemerCommissie
  'Winstmarge', // winstmarge
]

const serieValues = {
  Datum: item => [item.day, item.month ? monthToString(item.month) : null, item.year].filter(el => !!el).join(' '),
  Enkele_ritten: item => item.aantal_enkele_ritten,
  Gemiddelde_prijs: item => item.enkeleRitPrijs,
  Gemiddelde_basisprijs: item => item.onderaannemerBasisPrijs,
  Gemiddelde_commisie: item => item.onderaannemerCommissie,
  Winstmarge: item => item.winstmarge,
}

const apiData = computed(() => {
  const result = clone(props.filters)
  result.is_bevestigd = props.is_bevestigd
  result.timefield = props.timefield
  return result
})
const { data } = useGetApi('/api/dashboard/stats/onderaanneming/simulatie', apiData, { watch: true })

const selectedCategory = ref('Winstmarge')

const categories = computed(() => {
  if (!data.value?.statistieken) return []
  return Object.keys(serieValues).filter(el => el !== 'Datum').map(el => ({ label: formatHeader(el), value: el }))
})
const chartCategories = computed(() => {
  if (!data.value?.statistieken) return []
  return data.value.statistieken.map(item => serieValues.Datum(item))
})
const chartValues = computed(() => {
  if (!data.value?.statistieken) return []
  // eslint-disable-next-line no-useless-call
  return data.value.statistieken.map(item => serieValues[selectedCategory.value].call(serieValues, item) || 0)
})
</script>
